<template>
  <header class="header-main_area">
    <el-drawer :visible.sync="drawer" direction="ltr" :before-close="handleClose">
      <el-tree :data="category" empty-text="..." :props="defaultProps" @node-click="handleChangeClissify"></el-tree>

      <!--       <div class="zz-classify">
        <ul>
          <li
            class="has-sub"
            :class="{ active: classifyId == item.categoryId }"
            v-for="item in category"
            :key="item.categoryId"
            @click="handleChangeClissify(item.categoryId)"
          >
            {{ item.categoryName | zxby }}
            <i v-if="item.sons && item.sons.length" class="ion-chevron-right"></i>
          </li>
        </ul>
      </div> -->
    </el-drawer>

    <div class="header-top_area">
      <div class="container">
        <div class="row">
          <div class="col-2">
            <div class="ht-left_area" @click="drawer = true" style="padding: 7px 15px">
              <i class="ion-navicon-round"></i>
            </div>
            <div class="ht-left_area" v-if="false">
              <div class="essential-stuff">
                <ul>
                  <li class="welcome-msg">
                    <span>{{ $i18n.Header.Welcome }}</span>
                  </li>
                  <li class="contact-info">
                    <span class="ion-android-call"></span>
                    <a :href="`tel://${shopInfo.tel}`">{{ shopInfo.tel }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-10">
            <div class="ht-right_area">
              <div class="ht-menu">
                <ul>
                  <template v-if="token">
                    <li>
                      <router-link to="/user">{{ $i18n.Header.My }}</router-link>
                    </li>
                  </template>
                  <template v-else>
                    <li>
                      <a @click="onSign">{{ $i18n.Header.My }}</a>
                    </li>
                  </template>
                  <!--             <li>
                    <router-link to="/cart">{{
                      $i18n.Header.Wishlist
                    }}</router-link>
                  </li> -->
                  <li v-if="!token">
                    <a @click="onSign">{{ $i18n.Header.Sign }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-middle_area">
      <div class="container">
        <div class="row">
          <div class="col-3 ">
            <div class="header-logo">
              <a href="/">
                <img :src="shopInfo.logo" :alt="shopInfo.shopName" />
                <!-- <img src="../../assets/logo.png" :alt="shopInfo.shopName" /> -->
              </a>
              <!-- <a href="/">{{ shopInfo.shopName }}</a> -->
            </div>
          </div>
          <div class="col-6  d-lg-block">
            <div class="hm-form_area">
              <div class="hm-searchbox">
                <input type="text" @keyup.enter="onSearch" placeholder="Buscar" v-model="searchTags" />
                <button class="torress-search_btn" type="submit" @click="onSearch">
                  <i class="ion-android-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-3  d-lg-block">
            <div class="hm-minicart_area">
              <ul>
                <li>
                  <router-link to="/cart">
                    <div class="minicart-icon">
                      <!-- <i class="ion-android-cart"></i> -->
                      <div class="cart-icon"></div>
                      <span class="item_total">€{{ cart.totalAmount || '0.00' }}</span>
                      <!-- <span class="item-count">{{ cart.total || 0 }}</span> -->
                    </div>
                    <!--  <div class="minicart-title">
                      <span class="item_total" >€{{ cart.totalAmount || '0.00' }}</span >
                    </div> -->
                  </router-link>
                  <ul class="minicart-body" v-if="cart.total&&isPC">
                    <loading :show="cartLoading" />
                    <li class="minicart-item_area" v-for="item in carts" :key="item.spuId">
                      <div class="minicart-single_item">
                        <div class="product-item_remove" @click="removeCartList(item)">
                          <span class="ion-android-close" title="Remove This Item"></span>
                        </div>
                        <div class="minicart-img">
                          <router-link :to="`/${item.spuId}`">
                            <img :src="item.cover" :alt="item.spuName" />
                          </router-link>
                        </div>
                        <div class="minicart-content">
                          <div class="product-name">
                            <h6>
                              <router-link :to="`/${item.spuId}`">{{ item.spuName | zxby }}</router-link>
                            </h6>
                          </div>
                          <span class="product-quantity">Cantidad {{ item.num }}</span>
                          <div class="price-box">
                            <span class="new-price">€{{ item.price }}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="price_content">
                        <div class="cart-subtotals">
                          <div class="products subtotal-list">
                            <span class="label">{{ $i18n.Header.TotalAmount }}</span>
                            <span class="value">€{{ cart.totalAmount }}</span>
                          </div>
                        </div>
                        <div class="minicart-button">
                          <router-link class="torress-btn torress-btn_fullwidth" to="/cart" >Ir a la carrito</router-link >
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-7 col-sm-7 d-block d-lg-none" v-if="false">
            <div class="mobile-menu_area">
              <ul>
                <li class="minicart-area">
                  <a href="cart.html"><i class="ion-bag"></i><span class="item-count">2</span></a>
                </li>
                <li>
                  <a href="#mobileMenu" class="mobile-menu_btn toolbar-btn color--white d-lg-none d-block">
                    <i class="ion-navicon"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bottom_area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 d-lg-block position-static">
            <div class="main-menu_area">
              <nav class="main_nav">
                <ul>
                  <li>
                    <router-link exact to="/">{{ $i18n.HOME_NAME }}</router-link>
                  </li>
                  <li>
                    <router-link exact to="/cart">{{ $i18n.CART_NAME }}</router-link>
                  </li>
                  <li>
                    <router-link exact to="/classify">{{ $i18n.CLASSIFY_NAME }}</router-link>
                  </li>
                  <!--       <li>
                    <router-link exact to='/about'>{{$i18n.ABOUT_NAME}}</router-link>
                  </li> -->
                  <li>
                    <router-link exact to="/contact">{{ $i18n.CONTACT_NAME }}</router-link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="coupon">
      <div class="pc main">
        <div class="header">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </div> -->
  </header>
</template>
<style lang="less" scoped>
.coupon{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background: rgba(0,0,0,.6);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  .main{
    background: #fff;
    width: 80vw;
    .header{
      height: 30px;
      text-align: right;
      font-size: 30px;
    }
  }
}
</style>
<script>
import { mapGetters, mapState } from 'vuex'
import loading from '@/views/public/loading.vue'
import { renewCart } from '@/api'
import { zxby } from '@/filters'
import judge from '../../judge.js'

export default {
  data() {
    return {
      isPC: false,
      defaultProps: {
        children: 'sons',
        label(data) {
          return zxby(data.categoryName)
        }
      },
      drawer: false,
      loginLoading: false,
      searchTags: ''
    }
  },
  components: {
    loading
  },
  created() {
    this.isPC = judge.judge();
    this.searchTags = this.$route.query.keyword

    this.$store.dispatch('getCategoryList')
    console.log(this.shopInfo)
  },
  computed: {
    ...mapGetters(['token', 'carts']),
    ...mapState(['shopInfo', 'cart', 'cartLoading', 'category']),
    classifyId() {
      const { id = 0 } = this.$route.query
      return id
    }
  },
  watch: {
    // '$route'()
  },
  methods: {
    handleNodeClick(e) {
      const id = e.categoryId
      this.$router.push(`/classify?id=${id}`)
    },
    handleChangeClissify(e) {
      this.drawer = false;
      const id = e.categoryId;
      this.$router.push(`/classify?id=${id}`)
    },
    handleClose(done) {
      // console.log('hhhh')
      done()
    },
    onSearch() {
      // /goods_spu/search
      const { name, query } = this.$route
      let params = { keyword: this.searchTags }
      if (name === 'classify') {
        params = { ...query, ...params }
      }
      // console.log(params)
      this.$router.push({ name: 'classify', query: params })
    },
    removeCartList(item) {
      // if (!confirm('¿Estás seguro de que deseas eliminar el producto?')) {
      //   return
      // }

      this.$confirm('¿Estás seguro de que deseas eliminar el producto?', 'Eliminar', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        type: 'error'
      })
        .then(() => {
          let cart = this.carts.filter(e => e.spuId != item.spuId)

          // [{"cart_id":"2396","sku_id":"item.sku_id","num":3}]
          let params = []
          for (var i = cart.length - 1; i >= 0; i--) {
            params.push({
              cart_id: cart[i].cartId,
              sku_id: cart[i].spuId,
              num: cart[i].num
            })
          }

          // this.cart = cart
          renewCart(params).then(res => {
            if (res.data.status === 'fail') {
              this.$toasted.error(res.data.msg)
              this.$store.dispatch('cartLoading', false)
            } else {
              this.$store.dispatch('onChangeCart')
            }
          })
        })
        .catch(() => {})
    },
    onSign() {
      this.$store.dispatch('openLoginBox', true)
    }
  }
}
</script>

import axios from 'axios'
import qs from 'qs'
import store from './store'
// import Utils from './utils'
import crypto from 'crypto'

axios.interceptors.response.use(
    response => {
        if (response.data && response.data.code === 2003) {
            // alert('登录信息信息失效，请重新登录')
            store.dispatch('clearData')
        }
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

// axios.create({
//     // baseURL: process.env.API_ROOT,
//     timeout: 20000,
//     headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'Accept': 'application/json'
//     }
// })
//正式环境
// const host = '//olemart.didigo.es/api' //api

// const host = '//durabol.com/api'
// const host = '//apidlbdev.didigo.es/api/'
const host = '//admin.dlb.didigo.es/api/'
    //测试环境
    // const host = '//duolabo.didigo.es/api'
    // const host = '//dev-olemart.wulabh.com/api' //api
    // const host = '//dev-admin.dlb.didigo.es'

// const host='//dev-olemart.didigo.es/api'
let api = {
    user: {
        //login: '/main/login',
        //login: '/main/loginEmail',
        login: '/main/loginByEmail',
        regist: '/main/registerByEmail',
        phoneCode: '/main/getPhoneCode',
        emailCode: '/main/verifyEmailCode',
        address: '/address/list', //详细地址
        addAddress: '/address/update' //添加地址
    },
    shop: {
        info: '/shop/get', // 店铺信息
        banner: '/a/getForPc'
    },
    goods: {
        cart: '/cart/list', //获取购物车内容
        // cart: '/cart/listWithShop', //获取购物车内容
        detail: '/goods_spu/get', // 商品详情
        addCart: '/cart/update', //添加商品到购物车
        renewCart: '/cart/renew', //更新购物车
        commentList: '/order_comment/list', //评论分页
        list: '/goods_spu/list',
        search: '/goods_spu/search' //搜索商品
    },
    indexRecommend: '/index/recommend', //首页推荐
    categoryList: '/category/list', // 商品分类
    orderPrepare: '/order/prepare', //计算订单信息
    orderCreate: '/order/create', //添加订单
    areaList: '/area/list', //获取省市信息
    cartUpdate: '/cart/update', // 单品数量调整
    changePassword: '/user/changePassword',
    orderList: '/order/list', //订单列表
    cancelOrder: '/order/cancel', //取消订单
    delOrder: '/order/delo', //删除订单
    deleteAddress: '/address/delete', //删除地址
    orderDetail: '/order/get', //获取订单详情
    readyToPay: '/order/readyToPay', //获取付款的参数
    footerMenu: '/article/list', //底部连接
    pageData: '/article/get', //获取单页内容
    mailCode: '/main/getEmailCode', //获取邮件验证码
    repwd: '/main/resetpwd', //重设密码
    couponList: '/coupon/list' //获取优惠券列表

}

// @param  {String}   ex  前缀
function merge(source, ex) {
    let target = {}
    for (let i in source) {
        if (typeof source[i] === 'object') {
            target[i] = merge(source[i], ex)
        } else {
            target[i] = ex + source[i]
        }
    }
    return target
}

api = merge(api, host)

export async function getCoupon(p) {
    const str = getSign(p)
    return axios.post(api.couponList, str)
}

export async function getMailCode(p) {
    return axios.post(api.mailCode, qs.stringify(p))
}

export async function setPwd(p) {
    return axios.post(api.repwd, qs.stringify(p))
}

export async function getCode(p) {
    return axios.post(api.user.phoneCode, qs.stringify(p))
}
export async function getemailCode(p) {
    return axios.post(api.user.emailCode, qs.stringify(p))
}

export async function login(p) {
    return axios.post(api.user.login, qs.stringify(p))
}
export async function regist(p) {
    return axios.post(api.user.regist, qs.stringify(p))
}
export async function getShopInfo(p) {
    return axios.post(api.shop.info, qs.stringify(p))
}
export async function getShopBanner(p) {
    return axios.post(api.shop.banner, qs.stringify(p))
}

export async function categoryList(p) {
    return axios.post(api.categoryList, qs.stringify(p))
}
export async function indexRecommend() {
    let a = axios.post(api.indexRecommend, qs.stringify({ limit: 6 }))
    let b = axios.post(api.shop.banner, qs.stringify({ type: 'index' }))
    return axios.all([a, b])
}

export async function goodsDetail(p) {
    const str = getSign(p)
    return axios.post(api.goods.detail, str)
}
export async function goodsSearch(p) {
    return axios.post(api.goods.search, qs.stringify(p))
}
export async function getGoodsList(p) {
    if (p.keyword) {
        return axios.post(api.goods.search, qs.stringify(p))
    } else {
        return axios.post(api.goods.list, qs.stringify(p))
    }
}

export async function getCart(p) {
    const str = getSign(p)
    return axios.post(api.goods.cart, str)
}

export async function addCart(p) {
    store.dispatch('cartLoading', true)
    const str = getSign(p)
    return axios.post(api.goods.addCart, str)
}

export async function renewCart(p) {
    store.dispatch('cartLoading', true)
    const str = getSign({ data: JSON.stringify(p) })
    return axios.post(api.goods.renewCart, str)
}
export async function commentList(p) {
    return axios.post(api.goods.commentList, qs.stringify(p))
}
export async function getAreaList(p) {
    return axios.post(api.areaList, qs.stringify(p))
}
export async function addAddress(p) {
    const str = getSign(p)
    return axios.post(api.user.addAddress, str)
}

export async function getAddress(p) {
    const str = getSign(p)
    return axios.post(api.user.address, str)
}

export async function orderPrepare(p) {
    const str = getSign(p)
    return axios.post(api.orderPrepare, str)
}

export async function orderCreate(p) {
    const str = getSign(p)
    return axios.post(api.orderCreate, str)
}
export async function setCartUpdate(p) {
    const str = getSign(p)
    return axios.post(api.cartUpdate, str)
}

export async function changePassword(p) {
    const str = getSign(p)
    return axios.post(api.changePassword, str)
}
export async function getOrderList(p) {
    const str = getSign(p)
    return axios.post(api.orderList, str)
}
export async function deleteAddress(p) {
    const str = getSign(p)
    return axios.post(api.deleteAddress, str)
}

export async function getOrderDetail(p) {
    const str = getSign(p)
    return axios.post(api.orderDetail, str)
}
export async function getPayConfig(p) {
    const str = getSign(p)
    return axios.post(api.readyToPay, str)
}

export async function getFooterMenu(p) {
    // const str = getSign(p)
    return axios.post(api.footerMenu, qs.stringify(p))
}

export async function getPageData(p) {
    // const str = getSign(p)
    return axios.post(api.pageData, qs.stringify(p))
}

export async function putCancelOrder(p) {
    const str = getSign(p)
    return axios.post(api.cancelOrder, str)
}

export async function putDelOrder(p) {
    const str = getSign(p)
    return axios.post(api.delOrder, str)
}

// export default ajax

function getSign(data) {
    for (let key in data) {
        // console.log(data[key], typeof data[key])
        if (!data[key] && data[key] !== 0) {
            delete data[key]
        }
    }

    let newkey = Object.keys(data).sort()
    let newObj = {}
    for (let i = 0; i < newkey.length; i++) {
        newObj[newkey[i]] = data[newkey[i]]
    }
    let o = {}

    o.token = store.state.user.token
    let time = new Date().getTime() + ''
    time = time.substring(0, 10)
    o.time = time
        // qs.stringify(data)+
        // console.log(this.toStr(newObj))
        // console.log(qs.stringify(newObj))
    o.sign = toStr(newObj) + store.state.user.secretKey + time
        // o.sign = 'num=1&skuId=10438$2y$08$cE1Oa2daYTZuUHRjUitCQOJCGMBTpjtaNW/pWTew9yAMQzjB4FeZK1564296456'
    o.sign = toMD5(o.sign)

    for (let key in data) {
        o[key] = data[key]
    }
    return qs.stringify(o)
}

function toMD5(str) {
    var md5 = crypto.createHash('md5')
    md5.update(str)
    return md5.digest('hex')
}

function toStr(obj) {
    let str = ''
    for (let key in obj) {
        if (!str) {
            str += key + '=' + obj[key]
        } else {
            str += '&' + key + '=' + obj[key]
        }
    }
    return str
}
<template>
  <div class="loading" v-show="show">
    <div class="text-center middle">
      <div class="main-loader">
        <span class="loader1"></span>
        <span class="loader2"></span>
        <span class="loader3"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loading',
  props: {
    show: {
      default: false
    }
  }
}

</script>

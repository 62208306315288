const i18 = {
  forget_pass: 'Recuperar contraseña', //找回密码
  ORDER_NAME: 'Pedido',
  ORDER_DESC: 'Pedido',
  HOME_NAME: 'Inicio',
  HOME_DESC: 'Inicio',
  CLASSIFY_NAME: 'Categorías',
  CLASSIFY_DESC: 'Categorías',
  CART_NAME: 'Carrito',
  CART_DESC: 'Carrito',
  ABOUT_NAME: 'Sobre nosotros',
  ABOUT_DESC: 'Sobre nosotros',
  CONTACT_NAME: 'Contacto',
  CONTACT_DESC: 'Contacto',
  USER_NAME: 'Perfil',
  USER_DESC: 'Perfil',
  DETAIL_NAME: 'Detalle del producto',
  DETAIL_DESC: 'Detalle del producto',
  ERROR_PHONE: 'Número de teléfono',
  ERROR_CODE: 'Código',
  ERROR_SERVER: 'Error del servidor, inténtalo de nuevo',
  LOGIN_SUCCESS: 'Inicio de sesión exitoso',
  ERROR_NONE: 'Error desconocido，inténtalo de nuevo',
  GET_CODE: 'OBTENER CÓDIGO',
  SENDING: 'Enviando...',
  SENDED: 'Enviado',
  LOADING: 'cargando',
  PLACE_SELECT: 'Corregir',
  SAVE_CHANGE: 'Guardar Cambios',
  ERROR_INPUT: 'Rellena todos los campos',
  NO_ORDER: 'Pedido no encontrado',
  NO_CONETNT: 'Sin Resultado',
  CLASSIFY_Categories: 'Categorías',
  PAGE_NEXT: 'Siguiente',
  PAGE_PREV: 'Anterior',
  ADD_TO_CART: 'Añadir',
  TAGS: 'Tags',
  Description: 'Descripción',
  Reviews: 'Comentarios',
  ADD_CART_SUCCESS: 'Añadido exitosamente',
  NO_STOCK: 'No hay stock',
  Header: {
    Welcome: 'Bienvenido',
    My: 'Mi Cuenta',
    Wishlist: 'Lista de deseos',
    Search: 'Buscar',
    Sign: 'Regístrate',
    TotalAmount: 'Total',
    TotalRebate: 'Total Rebate'
  },
  Contact: {
    Address: 'Dirección',
    Phone: 'Teléfono',
    Email: 'Correo electrónico'
  },
  Password: {
    ERROR_CHANGE: 'No se ha podido cambiar, inténtelo de nuevo',
    SUCCESS_CHANGE: 'Se ha cambiado exitosamente',
    Current_Password: 'Contraseña actual(Dejar en blanco si no desea guardar los cambios)',
    New_Password: 'Nueva Contraseña (Dejar en blanco si no desea guardar los cambios)',
    Confirm_Password: 'Confirmar Nueva Contraseña'
  },
  Order: {
    Title: 'MIS PEDIDOS',
    Flow: ['Pedido', 'Pago', 'Reparto ', 'Recibido', 'Evaluación'],
    ConsigneeInformation: 'Información del destinatario',
    ContactInformation: 'Información del contacto',
    ModeDistribution: 'Modo de distribución',
    Shipping_Address: 'Informarción de envio',
    Freight: 'Envío',
    PaymentMethod: 'Método de pago',
    TotalGoods: 'Total de los productos',
    AmountPayable: 'Pago total',
    BuyerMessage: 'Mensaje al vendedor',
    SubmitMessage: 'Enviar mensaje',
    AmountGoods: 'Total de los productos',
    Discounts: 'Descuentos',
    TotalAmountText: 'Total a pagar',
    PayLoading: 'Procesando pago...',
    PayText: 'Pagar ahora',
    th: {
      ORDER: 'PEDIDO',
      DATE: 'FECHA',
      STATUS: 'ESTADO',
      TOTAL: 'TOTAL',
      D_TIME: 'Empresa de Transporte', //运输公司
      D_NO: 'Número de Envío' //运单号
    },
    Status: ['Total', 'Pendiente', 'Pagado', 'Enviado', 'Terminado', 'Evaluado']
  },
  Address: {
    Address: 'Dirección',
    Title: 'Dirección de Envío',
    Add: 'Añadir Dirección',
    Tip: 'Consejo: No guardar más de 10 direcciones',
    th: {
      Consignee: 'Destinatario',
      ConsigneePlaceholder: 'Destinatario',
      Detailed_Address: 'Detalle de la dirección',
      Phone_Number: 'Teléfono',
      PhoneNumberPlaceHolder: 'Número de teléfono',
      Postal: 'Código postal',
      PostalPlaceholder: 'Código postal',
      Email: 'Buzón',
      EmailPlaceHolder: 'Correo electrónico',
      Area: 'Dirección',
      SetDefault: 'Establecer como predeterminado',
      SAVE: 'GUARDAR'
    },
    ErrorConsignee: 'Rellena correctamente el Destinatario',
    ErrorArea: 'Rellena correctamente el Area',
    ErrorAddress: 'Rellena correctamente la dirección',
    ErrorPostcode: 'Rellena correctamente el Código postal',
    DELETE_SUCCESS: 'Eliminado exitosamente',
    DELETE_ERROR: 'Error al eliminar',
    SuccessAddress: 'Dirección añadida'
  },

  ShopCart: {
    Total: 'Total',
    Express: 'Envío',
    Subtotal: 'Subtotal',
    Cupones:"Cupones",
    ADD_ADDRESS: 'Añadir dirección de envio',
    ADD_ERROR: 'No se ha podido realizer el pedido',
    ADD_SUCCESS: 'Pedido realizado exitosamente',
    BuyText: 'Pulsa para comprar',
    EmptyCartText: 'Carrito vacío，',
    TipEmptyAddress: 'Añade una dirección，',
    SelectAddressText: 'Elige una dirección',
    AddText: 'Haz click para agregar',
    TotalText: 'Total',
    Checkout: 'Enviar pedido',
    th: {
      remove: 'eliminar',
      images: 'imagenes',
      Product: 'Producto',
      UnitPrice: 'Precio por unidad',
      Quantity: 'Cantidad',
      Total: 'Total'
    }
  },
  User: {
    Menu: {
      Order: 'Mis pedidos',
      Password: 'Cambiar Contraseña',
      Address: 'Dirección',
      Logout: 'Cerrar Sesión',
      Info: 'Información de cuenta',
      coupon:'CUPONES'
    },
    ConfirmLogout: 'Desea borrar?',
    ConfirmLogoutTitle: 'Atención',
    ConfirmButtonText: 'Confirmar',
    CancelButtonText: 'Cancelar',
    ClearSuccess: 'Se ha borrado exitosamente!',
    LoginTitle: 'Iniciar Sesión / Registrarse',
    LoginButton: 'Iniciar Sesión / Registrarse',
    Login: 'Iniciar Sesión',
    Register: 'Registrarse',
    Password: 'Contraseña',
    Passwordtwo: 'Intenta introducir la contraseña',
    Code: 'Código',
    CodePlaceholder: 'Introduzca el código de entrada'
  },
  Footer: {
    Products: 'Productos',
    Company: 'Nuestra Empresa',
    AboutUs: 'Sobre Nosotros',
    ContactUs: 'Contacto',
    Account: 'Tu Cuenta',
    Addresses: 'Direcciones',
    Orders: 'Pedidos',
    ChangePassword: 'Cambiar Contraseña',
    Email: 'Correo electrónico',
    Phone: 'Teléfono',
    Classify: 'Categorías'
  }
}

export default i18

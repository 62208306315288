function judge() {
    var u = navigator.userAgent
    /**判断是在Android终端内*/
    const isInAndroid = () => {
        return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
    }
    /**判断是在iOS终端内*/

    const isInIOS = () => {
        return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    }

    if(isInAndroid()){
        return false    //android机器的话  就是返回1
    }else if(isInIOS()){
        return false   //ios 设备返回2
    }else{
      return true
    }
}
export default {
  judge
}

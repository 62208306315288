<template>
  <div class="product-box">
    <loading :show="loading" />
    <div class="product-img">
      <router-link :to="`/${list.spuId}`">
        <img :src="list.cover" :alt="list.spuName" />
      </router-link>
    </div>
    <div class="product-content">
      <div class="title">
        <router-link :to="'/' + list.spuId ">{{ list.spuName | zxby }}</router-link>
      </div>
      <div class="price-box">
        <span class="old-price" style="text-decoration: none; color: #e01212; font-size:22px; font-weight:bold" 
        v-if="(list.wholesaleNum>0)">
          <span class="mg">{{ list.wholesale }}</span>€</span>
        <span class="new-price" v-else >€{{ list.price }}</span>
      </div>
      <div class="add-cart">
        <template v-if="cartData.num > 0">
          <i @click="addCart(0)" class="iconfont icon-Delete"></i>
          <div>{{ cartData.num }}</div>
        </template>
        <i @click="addCart(1)" class="iconfont icon-Add"></i>
      </div>
      <!-- <div style="font-size:14px;color:#e01212;" v-if="list.wholesaleNum>0">A partir de <span style="color: #e01212;">{{list.wholesaleNum}}</span> unidades:{{list.wholesale}}</div>   -->
    </div>
  </div>
</template>
<style lang="less">
.product-box {
  position: relative;
  .mg{
    font-size:20px !important; 
  }
  .loading {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
  }
}
</style>
<script>
import loading from "@/views/public/loading.vue";

import { setCartUpdate } from "@/api";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  components: {
    loading,
  },
  watch: {
    carts(e) {},
  },

  computed: {
    ...mapGetters(["carts"]),
    ...mapState(["user"]),
    cartData() {
      let data;
      if (this.carts && (this.list.skus || this.list.skuId)) {
        // console.log(this.list)
        data = this.carts.find((e) => {
          return e.skuId == (this.list.skuId || this.list.skus[0].skuId);
        });
      }
      return data || {};
    },
  },
  props: ["list"],
  methods: {
    find() {
      // 查看当前商品是否在购物车里面
    },
    addCart(data,arr) {
      console.log(data,arr*10/10)
      if (!this.user || !this.user.token) {
        this.$toasted.error("Iniciar sesión");
        this.$store.dispatch("openLoginBox", true);

        return false;
      }
      if (this.loading) return;
      this.loading = true;
      let params = { num: 1 };

      if (this.list.skuId) {
        params.sku_id = this.list.skuId;
      } else {
        if (this.list.skus[0]) {
          params.sku_id = this.list.skus[0].skuId;
        }
      }
      let num = this.cartData.num * 1;
      if (num) {
        params.num = !data ? Math.abs(num - 1) : num + 1;
      }
      // console.log(params)

      setCartUpdate(params).then((res) => {
     
        if (res.data.data) {
          this.$store.dispatch("onChangeCart");
        } else {
          this.$toasted.error(res.data.msg);
        }
        setTimeout(() => {
          this.loading = false;
        }, 0);
      });
    },
  },
};
</script>

<template>
  <modal
    name="login-box"
    height="auto"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="modal-close" @click="beforeClose">
      <i class="ion-android-close"></i>
    </div>

    <div id="login">
      <loading :show="loginLoading" />
      <div class="container">
        <div class="tab_v">
          <span :class="{ on: type === 0 }" @click="type = 0">{{
            $i18n.User.Login
          }}</span>
          <span :class="{ on: type === 1 }" @click="type = 1">{{
            $i18n.User.Register
          }}</span>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="login-form">
              <h4 class="login-title">{{ $i18n.User.LoginTitle }}</h4>
              <div class="row">
                <template v-if="typecode === 0">
                  <div class="col-md-12 col-12">
                    <label>{{ $i18n.Address.th.Email }}</label>
                    <input
                      type="email"
                      v-model="phone"
                      :placeholder="$i18n.Address.th.EmailPlaceHolder"
                    />
                  </div>
                  <div class="col-12 mb--20">
                    <label>{{ $i18n.User.Password }}</label>
                    <div class="myinput">
                      <input
                        type="password"
                        v-model="password"
                        :placeholder="$i18n.User.Password"
                      />
                      <!--<div @click="getCode" class="getcode">
                        {{ getCodeText }}
                      </div>-->
                    </div>
                  </div>
                  <template v-if="type === 1">
                    <div class="col-12 mb--20">
                      <label>{{ $i18n.User.Passwordtwo }}</label>
                      <div class="myinput">
                        <input
                          type="password"
                          v-model="passwordtwo"
                          :placeholder="$i18n.User.Passwordtwo"
                        />
                        <!--<div @click="getCode" class="getcode">
                          {{ getCodeText }}
                        </div>-->
                      </div>
                    </div>

                    <div class="col-md-12 login-line">
                      <button @click="onRegist" class="torress-login_btn">
                        {{ $i18n.User.Register }}
                      </button>
                      <div class="link" @click="toforget">
                        {{ $i18n.forget_pass }}
                      </div>
                    </div>
                  </template>

                  <div class="col-md-12 login-line" v-else>
                    <button @click="onLogin" class="torress-login_btn">
                      {{ $i18n.User.Login }}
                    </button>
                    <div class="link" @click="toforget">
                      {{ $i18n.forget_pass }}
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div class="col-12 mb--20">
                    <label>{{ $i18n.User.Code }}</label>
                    <div class="myinput">
                      <input
                        type="text"
                        v-model="code"
                        :placeholder="$i18n.User.CodePlaceholder"
                      />
                      <!-- <div @click="getCode" class="getcode">
                         {{ getCodeText }}
                       </div>-->
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button @click="onRegist2" class="torress-login_btn">
                      {{ $i18n.User.Register }}
                    </button>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<style lang="less">
@border-color: #ebebeb;
@body-text_color: #333333;
@primary-color: #fed100;
@bg-white_color: #fff;
@bg-nobel_color: #999999;
@heading-color: #333;

#login {
  position: relative;
  .login-line {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .link {
      cursor: pointer;
      &:hover {
        color: @primary-color;
      }
    }
    .torress-login_btn {
      margin-top: 0;
      margin-right: 30px;
    }
  }
  .loading {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.57);
  }
}

.myinput {
  position: relative;
  display: flex;

  .getcode {
    margin-bottom: 15px;
    line-height: 45px;
    cursor: pointer;
    padding: 0 19px;
    background-color: @primary-color;
    color: @bg-white_color;
    flex-shrink: 0;
  }
}
.tab_v {
  display: flex;
  padding: 20px 0 0;
  text-align: center;
  span {
    margin: 0 50px;
    cursor: pointer;
    &.on {
      color: @primary-color;
    }
  }
}
</style>
<script>
import loading from '@/views/public/loading.vue'
import { getemailCode, getCode, login, regist } from '@/api'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      type: 0,
      typecode: 0,
      phone: '',
      code: '',
      password: '',
      passwordtwo: '',
      status: 0, // 0等待发送，1发送中，2已发送
      timeout: 60
    }
  },
  components: {
    loading
  },
  watch: {
    loginBoxOpen(e) {
      if (e && !this.$store.state.phone) {
        this.$modal.show('login-box', { height: 'auto' })
      } else {
        this.$modal.hide('login-box')
        // this.$modal.hide('login-box', { height: 'auto' })
      }
    }
  },
  computed: {
    getCodeText() {
      if (this.status === 0) {
        return this.$i18n.GET_CODE
      } else if (this.status === 1) {
        return this.$i18n.SENDING
      } else if (this.status === 2) {
        return `${this.$i18n.SENDED} (${this.timeout})`
      }
    },
    ...mapState(['loginBoxOpen', 'loginLoading'])
  },
  methods: {
    toforget() {
      this.beforeClose()
      this.$router.push('/forget')
    },
    onLogin() {
      if (!this.phone) {
        return this.$toasted.error(this.$i18n.EmailPlaceHolder)
      }
      if (!this.password) {
        return this.$toasted.error(this.$i18n.User.Password)
      }
      this.$store.dispatch('setLoginLoading', true)
      login({ email: this.phone, password: this.password })
        .then(res => {
          if (res.status === 200 && res.data) {
            if (res.data.status !== 'SUCCESSS') {
              // this.$store.dispatch('openLoginBox', false)
              // this.$store.dispatch('setLoginLoading', false)
              this.$toasted.error(res.data.msg || this.$i18n.ERROR_NONE)
              this.status = 0

              // 关闭窗口
            } else {
              this.registclose()
              this.beforeClose()
              this.$toasted.success(this.$i18n.LOGIN_SUCCESS)
              this.$store.dispatch('setUserInfo', res.data.data)
            }
          } else {
            return this.$toasted.error(this.$i18n.ERROR_SERVER)
          }
          this.$store.dispatch('setLoginLoading', false)
        })
        .catch(_ => {
          this.$store.dispatch('setLoginLoading', false)
        })
    },
    onRegist() {
      if (!this.phone) {
        return this.$toasted.error(this.$i18n.EmailPlaceHolder)
      }
      if (!this.password) {
        return this.$toasted.error(this.$i18n.User.Password)
      }
      if (this.passwordtwo !== this.password) {
        return this.$toasted.error(
          this.$i18n.User.Passwordtwo + '  ' + this.$i18n.User.Password
        )
      }
      this.$store.dispatch('setLoginLoading', true)
      regist({
        email: this.phone,
        password: this.password,
        code: this.code,
        rePassword: this.passwordtwo
      })
        .then(res => {
          if (res.status === 200 && res.data) {
            if (res.data.status !== 'SUCCESSS') {
              // this.$store.dispatch('openLoginBox', false)
              // this.$store.dispatch('setLoginLoading', false)
              this.$toasted.error(res.data.msg || this.$i18n.ERROR_NONE)
              this.status = 0

              // 关闭窗口
            } else {
              //this.beforeClose()
              this.$toasted.success(this.$i18n.LOGIN_SUCCESS)
              //this.$store.dispatch('setUserInfo', res.data.data)
              this.typecode = 1
              //his.registclose()
            }
          } else {
            return this.$toasted.error(this.$i18n.ERROR_SERVER)
          }
          this.$store.dispatch('setLoginLoading', false)
        })
        .catch(_ => {
          this.$store.dispatch('setLoginLoading', false)
        })
    },
    onRegist2() {
      if (!this.code) {
        return this.$toasted.error(this.$i18n.ERROR_CODE)
      }
      this.$store.dispatch('setLoginLoading', true)
      getemailCode({ email: this.phone, code: this.code })
        .then(res => {
          if (res.status === 200 && res.data) {
            if (res.data.status !== 'SUCCESSS') {
              // this.$store.dispatch('openLoginBox', false)
              // this.$store.dispatch('setLoginLoading', false)
              this.$toasted.error(res.data.msg || this.$i18n.ERROR_NONE)
              this.status = 0

              // 关闭窗口
            } else {
              //this.beforeClose()
              this.$toasted.success(this.$i18n.LOGIN_SUCCESS)
              //this.$store.dispatch('setUserInfo', res.data.data)
              this.typecode = 0
              this.type = 0
              this.registclose()
            }
          } else {
            return this.$toasted.error(this.$i18n.ERROR_SERVER)
          }
          this.$store.dispatch('setLoginLoading', false)
        })
        .catch(_ => {
          this.$store.dispatch('setLoginLoading', false)
        })
    },
    registclose() {
      this.phone = ''
      this.code = ''
      this.password = ''
      this.passwordtwo = ''
    },
    beforeOpen() {},
    beforeClose() {
      this.$store.dispatch('openLoginBox', false)
      this.$store.dispatch('setLoginLoading', false)
    },
    getCode() {
      if (this.status) return

      if (!this.phone) {
        this.$toasted.error(this.$i18n.EmailPlaceHolder)
        return
      }

      let params = {
        phone: this.phone
      }
      this.status = 1
      getCode(params)
        .then(res => {
          res = res.data
          if (res.status === 'fail') {
            this.status = 0
            return this.$toasted.error(res.msg)
          }
          if (res.status === 'SUCCESSS') {
            this.status = 2
            let timeout = setInterval(() => {
              this.timeout--
              if (this.timeout < 1) {
                clearInterval(timeout)
                this.status = 0
                this.timeout = 60
              }
            }, 1000)
          }
        })
        .catch(err => {
          this.$toasted.error(this.$i18n.EmailPlaceHolder)
        })
    }
  }
}
</script>

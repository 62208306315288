export function zxby(e) {
  const title = e.split('/')
  if (Array.isArray(title) && title[1]) {
    return title[1]
  }
  return e
}
export function zxbydet(value) {
  return value.split('@@')[1] || value
}

import Vue from 'vue'
import Router from 'vue-router'
import children from './views/public/children'
import i18n from './i18n'

// 分类
import home from './views/goods/home.vue'

const classify = resolve => require(['@/views/goods/classify'], resolve)

const goodsDetail = resolve => require(['@/views/goods/detail'], resolve)
const shopCart = resolve => require(['@/views/goods/shopcart'], resolve)
const orderDetail = resolve => require(['@/views/goods/order'], resolve)

// 单页
const about = resolve => require(['@/views/about/index.vue'], resolve)
const contact = resolve => require(['@/views/contact/index.vue'], resolve)
const sign = resolve => require(['@/views/user/sign'], resolve)
const login = resolve => require(['@/views/user/login'], resolve)

// 用户
const user = resolve => require(['@/views/user'], resolve)
const userInfo = resolve => require(['@/views/user/info'], resolve)

const address = resolve => require(['@/views/user/address'], resolve)
const password = resolve => require(['@/views/user/password'], resolve)
const order = resolve => require(['@/views/user/order'], resolve)
const coupon = resolve => require(['@/views/user/coupon'], resolve)

// 单页
const page = resolve => require(['@/views/page/index.vue'], resolve)
const forget = resolve => require(['@/views/user/forget.vue'], resolve)

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: home,
      meta: {
        keepAlive: true,
        name: i18n.HOME_NAME,
        desc: i18n.HOME_DESC
      }
    },
    {
      path: '/classify',
      name: 'classify',
      component: classify,
      meta: {
        keepAlive: true,
        name: i18n.CLASSIFY_NAME,
        desc: i18n.CLASSIFY_DESC
      }
    },

    {
      path: '/cart',
      name: 'shopCart',
      component: shopCart,
      meta: {
        name: i18n.CART_NAME,
        desc: i18n.CART_DESC
      }
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: about,
    //   meta: {
    //     keepAlive: true,
    //     name: i18n.ABOUT_NAME,
    //     desc: i18n.ABOUT_DESC
    //   }
    // },
    {
      path: '/contact',
      name: 'contact',
      component: contact,
      meta: {
        keepAlive: true,
        name: i18n.CONTACT_NAME,
        desc: i18n.CONTACT_DESC
      }
    },
    // {
    //     path: '/sign',
    //     name: 'sign',
    //     component: sign
    // },
    // {
    //     path: '/login',
    //     name: 'login',
    //     component: login
    // },
    {
      path: '/user',
      component: user,
      children: [
        {
          path: 'userInfo',
          name: 'user',
          component: userInfo,
          meta: {
            name: i18n.USER_NAME,
            desc: 'Información de cuenta'
          }
        },
        {
          path: '',
          name: 'order',
          component: order,
          meta: {
            name: i18n.USER_NAME,
            desc: 'Mis pedidos'
            // desc: '我的订单'
          }
        },
        {
          path: 'address',
          name: 'address',
          component: address,
          meta: {
            name: i18n.USER_NAME,
            desc: 'Dirección de Envío'
            // desc: '我的收货地址'
          }
        },
        {
          path: 'password',
          name: 'password',
          component: password,
          meta: {
            name: i18n.USER_NAME,
            desc: 'Cambiar contraseña'
            // desc: '修改密码'
          }
        },
        {
          path: 'coupon',
          name: 'coupon',
          component: coupon,
          meta: {
            name: i18n.USER_NAME,
            desc: 'coupon'
            // desc: '修改密码'
          }
        }
      ]
    },
    {
      path: '/page/:id',
      name: 'page',
      component: page
    },
    {
      path: '/order/:id',
      name: 'orderDetail',
      component: orderDetail,
      meta: {
        name: i18n.ORDER_NAME,
        desc: i18n.ORDER_DESC
      }
    },
    {
      path: '/forget',
      name: 'forgetPassword',
      component: forget,
      meta: {
        name: 'Recuperar contraseña'
      }
    },
    {
      path: '/:id',
      name: 'goodsDetail',
      component: goodsDetail,
      meta: {
        name: i18n.DETAIL_NAME,
        desc: i18n.DETAIL_DESC
      }
    },

    {
      path: '*',
      redirect: '/'
    }
  ]
})

const shopTitle = () => {
  let shop_info = window.sessionStorage.getItem('shop_info')
  if (shop_info) {
    shop_info = JSON.parse(shop_info)
    return shop_info.shopName
  }
}
router.beforeEach(({ meta }, _, next) => {
  // console.log(this.$store)
  document.title = `${meta.name} - ${shopTitle() || ''}`
  next()
})

export default router

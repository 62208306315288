import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './filters' // 全局filter

import './assets/app.less'

import VueAwesomeSwiper from 'vue-awesome-swiper'
// // require styles
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper)

import utils from './utils.js'
Vue.prototype.$utils = utils
Vue.prototype.$url = 'utils'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import Toasted from 'vue-toasted'
Vue.use(Toasted, {
  theme: 'toasted-primary',
  position: 'top-center',
  duration: 5000
})

import VModal from 'vue-js-modal'
Vue.use(VModal)
import i18n from './i18n'
Vue.prototype.$i18n = i18n

Vue.config.productionTip = false
import {
  MessageBox,
  Drawer,
  Form,
  FormItem,
  Button,
  Input,
  Tree,
  Message,

} from 'element-ui'
Vue.use(Tree)
Vue.use(Form)
Vue.use(Input)

Vue.use(Button)
Vue.use(FormItem)


Vue.use(Drawer)
// Vue.use(CarouselItem)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

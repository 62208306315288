<template>
  <div id="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-4">
          <div class="footer-widgets_title">
            <h4>{{ $i18n.Footer.Products }}</h4>
          </div>
          <div class="footer-widgets">
            <ul>
              <li>
                <router-link to="/classify">{{
                  $i18n.Footer.Classify
                }}</router-link>
              </li>
              <!-- <li><a href="javascript:void(0)">Prices Drop</a></li> -->
              <!-- <li><a href="javascript:void(0)">New Products</a></li> -->
              <!-- <li><a href="javascript:void(0)">Best Sales</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4">
          <div class="footer-widgets_title">
            <h4>{{ $i18n.Footer.Company }}</h4>
          </div>
          <div class="footer-widgets">
            <ul>
              <li v-for="item in menus" :key="item.title">
                <router-link :to="`/page/${item.articleId}`">{{
                  item.title
                }}</router-link>
                <!-- <a href="javascript:void(0)"></a> -->
              </li>
              <!-- <li><a href="/about">{{$i18n.Footer.AboutUs}}</a></li> -->
              <!-- <li><a href="/contact">{{$i18n.Footer.ContactUs}}</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4">
          <div class="footer-widgets_title">
            <h4>{{ $i18n.Footer.Account }}</h4>
          </div>
          <div class="footer-widgets">
            <ul>
              <li>
                <a href="/user/address">{{ $i18n.Footer.Addresses }}</a>
              </li>
              <li>
                <a href="/user">{{ $i18n.Footer.Orders }}</a>
              </li>
              <li>
                <a href="/user/password">{{ $i18n.Footer.ChangePassword }}</a>
              </li>
              <!-- <li><a href="/user?idx=4">账号信息</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="footer-widgets_title">
            <h4>{{ $i18n.Footer.ContactUs }}</h4>
          </div>
          <div class="footer-widgets footer-contact_info">
            <ul>
              <li>
                <i class="ion-ios-location"></i>
                <span>{{ shopInfo.address }}</span>
              </li>
              <li>
                <i class="ion-ios-email"></i>
                <!-- <span>{{ $i18n.Footer.Email }}:</span> -->
                <a :href="`mailto:${shopInfo.email}`">{{ shopInfo.email }}</a>
              </li>
              <li>
                <i class="ion-android-call"></i>
                <!-- <span>{{ $i18n.Footer.Phone }}:</span> -->
                <div class="cellphone-number_area">
                  <a :href="`tel://${shopInfo.tel}`">{{ shopInfo.tel }}</a>
                </div>
              </li>
              <!--         <li>
                <i class="ion-ios-calendar-outline"></i>
                <span>Mon-Sat 9:00pm – 5:00pm. Sun: Weekend</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getFooterMenu } from '@/api'
export default {
  data() {
    return {
      menus: []
    }
  },
  computed: {
    ...mapState(['shopInfo'])
  },
  created() {
    getFooterMenu({ articleMenuId: 1 }).then(res => {
      this.menus = (res.data && res.data.data && res.data.data.list) || []
    })
  }
}
</script>

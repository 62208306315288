<template>
  <div id="app">
    <v-header />
    <breadcrumb v-if="showBreadcrumb" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <v-loading :show="appLoading" />
    <!-- loginbox -->
    <login />
    <v-backtop />
    <v-footer />
  </div>
</template>
<script>
import login from './views/user/login.vue';
import header from './views/public/header.vue';
import breadcrumb from './views/public/breadcrumb.vue';
import loading from './views/public/loading.vue';
import footer from './views/public/footer.vue';
import backtop from '@/components/backtop';
import { mapState } from 'vuex';
import { getShopInfo, getCart } from '@/api';

export default {
  data() {
    return {
      drawer: true
    };
  },

  watch: {
    appLoading(e) {
      /* return
      if (this.setTimeLoading) {
        clearTimeout(this.setTimeLoading)
      }
      if (e) {
        this.setTimeLoading = setTimeout(() => {
          this.$store.dispatch('setAppLoading', false)
        }, 10000)
      }*/
    },
    'user.levelId'(e) {
      if (e) {
        this.getCart();
      }
    },
    cartChanged() {
      this.getCart();
    }
  },
  computed: {
    showBreadcrumb() {
      const name = this.$route.name;
      // console.log('showBreadcrumb', name)
      return name !== 'home';
    },
    ...mapState(['shopInfo', 'appLoading', 'user', 'cartChanged'])
  },
  methods: {
    changeTitle(data) {
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = ' icon';
      link.href = data.logo;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.title = data.shopName;
    },
    getCart() {
      getCart({}).then(res => {
        if (res.data.data) {
          this.$store.dispatch('setCart', res.data.data);
        }
      });
    }
  },
  created() {
    if (this.user.token) {
      this.getCart();
    }
    if (this.shopInfo.shopId) {
      this.changeTitle(this.shopInfo);
      return;
    }
    getShopInfo({}).then(res => {
      if (res.status === 200 && res.data) {
        if (res.data.status !== 'SUCCESSS') {
          this.$toasted.error(res.data.msg || this.$i18n.ERROR_NONE);
          // 这里应该有相应的处理
          /* setTimeout(() => {
           // window.location.reload()
          }, 1000)*/
        } else {
          const data = res.data.data;
          this.$store.dispatch('setShopInfo', data);
          this.changeTitle(data);
        }
      } else {
        return this.$toasted.error(this.$i18n.ERROR_SERVER);
      }
    });
  },
  components: {
    login,
    // coupon,
    vHeader: header,
    breadcrumb,
    vLoading: loading,
    vFooter: footer,
    vBacktop: backtop
  }
};
</script>
<style lang="less">
@fff: #000;
#footer {
  //background-color: #303d4e;
  margin-top: 55px;
  padding: 10px;

  .container {
    padding: 45px 0 50px;

    color: @fff;

    h4 {
      color: @fff;
    }
  }

  h4 {
    font-weight: 400;
    padding-bottom: 20px;
    font-size: 22px;
    margin-bottom: 0;
  }

  li {
    padding-bottom: 10px;

    a {
      color: @fff;
    }
  }

  .footer-contact_info > ul > li {
    position: relative;
    padding-left: 30px;
    padding-bottom: 0;
    margin-bottom: 20px;

    i {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 24px;
    }

    span {
      cursor: text;
      margin-right: 5px;
    }

    .cellphone-number_area {
      display: inline-block;
      vertical-align: middle;

      a {
        display: block;
      }
    }
  }
}
</style>

<template>
  <div></div>
  <!--   <div class="breadcrumb-area" v-if='meta.name'>
    <div class="container">
      <div class="breadcrumb-content">
        <h2>{{meta.desc}}</h2>
        <ul>
          <li><a href="/">{{$i18n.HOME_NAME}}</a></li>
          <li v-if='route.path != "/"' class="active">{{meta.name}}</li>
        </ul>
      </div>
    </div>
  </div> -->
</template>
<script>
export default {
  data() {
    return {
      route: {}
    }
  },
  computed: {
    meta() {
      return this.route.meta || {}
    }
  },
  created() {
    this.route = this.$route
  },
  watch: {
    $route(route) {
      this.route = route
    }
  }
}
</script>

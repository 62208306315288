import { render, staticRenderFns } from "./productBox.vue?vue&type=template&id=b343a7d0"
import script from "./productBox.vue?vue&type=script&lang=js"
export * from "./productBox.vue?vue&type=script&lang=js"
import style0 from "./productBox.vue?vue&type=style&index=0&id=b343a7d0&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <keep-alive>
    <div class="home container">
      <swiper class="home-swiper" :options="swiperOption" ref="mySwiper" v-show="banner"
        v-if="banner && banner.length > 0">
        <!-- slides -->
        <swiper-slide v-for="item in banner" :style="{ backgroundImage: `url(${item.img})` }">
          <a :href="item.linkUrl">
            <img class="swiper-image" :src="item.img" alt="" />
          </a>
        </swiper-slide>
        <!-- Optional controls -->
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button swiper-button-prev iconfont icon-left" slot="button-prev"></div>
        <div class="swiper-button swiper-button-next iconfont icon-right" slot="button-next"></div>
      </swiper>

      <template v-for="item in recommend">
        <div class="classify-cover" :style="{
            backgroundImage: `url(${item.recommendPic})`,
            // backgroundSize: 'cover'
          }">
          <router-link :to="`classify?id=${item.categoryId}`" :data-name="item.categoryName">
            <img :src="item.recommendPic" :alt="item.categoryName" />
          </router-link>
        </div>
        <div class="row classify-goods">
          <div class="col-lg-2 col-md-6 col-sm-6" v-for="list in item.list">
            <product-box :list="list" />
          </div>
        </div>
      </template>
    <coupon/>
      
    </div>
  </keep-alive>
</template>
<script>
  import {
    mapState
  } from "vuex";
  import {
    indexRecommend
  } from "@/api";
  import productBox from "@/views/public/productBox";
  import coupon from "@/views/public/coupon";
  // import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  // import 'swiper/dist/css/swiper.css'

  export default {
    name: "home",

    data() {
      return {
        daiji: true,
        ads: {},
        carts2: [], // 购物车里面的内容
        recommend: [],
        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 30,
          loop: true,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
      };
    },
    components: {
      productBox,
      coupon
    },
    methods: {
      // 判断当前商品是否在购物车
      addCart(item, data) {
        //
      },
      beforeClose() {
        this.$modal.hide('address-box')
      },
      beforeOpen() {},
      Link() {
        this.$store.dispatch('openLoginBox', true)
        this.$modal.hide('address-box')

      }
    },
    watch: {
      carts(v) {
        this.carts2 = v;
      },
      banner(v) {
        if (v && v.length) {
          setTimeout(() => {
            this.swiper = this.$refs.mySwiper.swiper;
            // console.log('this.$refs.mySwiper', this.$refs.mySwiper)

            this.swiper.el.onmouseover = function () {
              this.swiper.navigation.$nextEl.removeClass("hide");
              this.swiper.navigation.$prevEl.removeClass("hide");
            };
            this.swiper.el.onmouseout = function () {
              this.swiper.navigation.$nextEl.addClass("hide");
              this.swiper.navigation.$prevEl.addClass("hide");
            };
          }, 1000);
        }
      },
    },
    computed: {
      banner() {
        let box = this.ads.pcIndex1;
        //let box = this.ads.index
        return box && box.ads;
      },
      // swiper() {
      //   return this.$refs.mySwiper && this.$refs.mySwiper.swiper
      // },
      ...mapState(["cart"]),
      ...mapState(["user"]),
    },
    created() {
      // this.$modal.show("address-box", { height: "auto" });
      this.$store.dispatch("setAppLoading", true);
      indexRecommend().then((res) => {
        this.$store.dispatch("setAppLoading", false);
        let a = res[0];
        let b = res[1];
        this.recommend = a.data.data || {};
        this.ads = b.data.data || [];
      });
    },
    mounted() {
      if (!this.user || !this.user.token) {

        // this.$store.dispatch('opencouponBox', true);

      }

      // current swiper instance
      // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
      // console.log('this is current swiper instance object', this.swiper)
      // this.swiper.slideTo(3, 1000, false)
    },
  };

</script>
<style lang="less">
  @border-color: #ebebeb;
  @body-text_color: #333333;
  @primary-color: #fed100;
  @bg-white_color: #fff;
  @bg-nobel_color: #999999;
  @heading-color: #333;

  .swiper {
    height: 300px;
    width: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      font-size: 25px;
      background-color: #fefefe;
    }
  }

  .home {
    position: relative;

    #add-address {

      // position: relative;
      // .modal-close {
      //   position: absolute;
      //   text-align: right;
      // }
      #modal_box {
        position: relative;
      }
    }

    .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 70px;

      .img_box {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          margin: 0;
          width: 150px;
        }
      }

      h3 {
        margin: 30px 0;
      }

      button {
        width: 180px;
        height: 50px;
        border-radius: 30px;
        border: 3px solid #fed200;
        // background-color: #FED200;
      }
    }

    .home-swiper {
      // height: 312px;
      width: 100%;
      margin-top: 60px;

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 25px;
        background-color: #fefefe;

        background-size: cover;
        background-position: center;
      }
    }

    .classify-cover {
      margin-top: 30px;
      margin-bottom: 30px;
      // min-height: 120px;
      background-color: #666;
      background-size: cover;
      background-position: center;

      img {
        max-width: 100%;
        display: block;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .classify-goods {
      // text-align: center;
      .row {
        display: flex;
        justify-content: center;
      }

      .col-md-6,
      .col-sm-6 {
        padding: 0;
      }
    }

    .swiper-image {
      width: 100%;
      min-height: 120px;
    }
  }

</style>

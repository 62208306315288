<template>
  <modal name="address-box" height="auto" @before-open="beforeOpen" @before-close="beforeClose">
    <div class="modal-close" @click="beforeClose">
      <i class="el-icon-close"></i>
    </div>
    <div id="modal_box">
      <div class="container">
        <div class="box">
          <div class="img_box">
            <img src="../../../src/assets/images/img.png" />
          </div>
          <h3>5€ GRATIS</h3>
          <p style="text-align:center">
            Regístrate ahora y consigue 5€ de descuento para primera compra
          </p>
          <button @click="Link">Registrarse</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<style lang="less">
  @border-color: #ebebeb;
  @body-text_color: #333333;
  @primary-color: #fed100;
  @bg-white_color: #fff;
  @bg-nobel_color: #999999;
  @heading-color: #333;

  #add-address {

    #modal_box {
      position: relative;
    }
  }

  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 70px 30px 70px;

    .img_box {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        margin: 0;
        width: 150px;
      }
    }

    h3 {
      margin: 30px 0;
    }

    button {
      width: 180px;
      height: 50px;
      border-radius: 30px;
      border: 3px solid #fed200;
      // background-color: #FED200;
    }
  }

</style>
<script>
  import Vue from 'vue'
  import {
    mapState
  } from 'vuex'

  export default {
    data() {
      return {

      }
    },
    props: {
      
    },

    watch: {
      couponBoxOpen(e) {
        if (e && !this.$store.state.token) {
          this.$modal.show('address-box', {
            height: 'auto'
          })
        } else {
          this.$modal.hide('address-box')
        }
      }
    },
    computed: {

      ...mapState(['couponBoxOpen'])
    },
    mounted(){
console.log('!!!')
    },
    methods: {
      Link() {
        this.$store.dispatch('openLoginBox', true)
        this.$store.dispatch('opencouponBox', false)

      },
      beforeOpen() {},
      beforeClose(data = false) {
        // 恢复默认值
        this.$store.dispatch('opencouponBox', false)
      }
    }
  }

</script>

import Vue from 'vue'
import Vuex from 'vuex'

import { categoryList } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart: {},
    addrList: [],
    addrEdit: {},
    loginBoxOpen: false,
    couponBoxOpen: false,
    user: JSON.parse(window.localStorage.getItem('user_info') || '{}'),
    loginLoading: false,
    shopInfo: JSON.parse(window.sessionStorage.getItem('shop_info') || '{}'),
    appLoading: false,
    cartChanged: 0, //用时间戳来监听购物车变化
    cartLoading: false, // 购物车重新加载
    areas: JSON.parse(window.localStorage.getItem('shop_areas') || '[]'),
    category: []
  },
  getters: {
    phone(state) {
      return state.user.phone
    },
    token(state) {
      return state.user.token
      // const str = state.user
      // return '123'
    },
    carts(state) {
      if (state.cart.total > 0) {
        return state.cart.list
      }
    }
  },
  mutations: {
    ADD_CART(state, goods = {}) {
      state.cart.push(goods)
    },
    SET_CART(state, goods = {}) {
      state.cart = goods
    },
    STORE_ADDR(state, addr = []) {
      state.addrList = addr
    },
    STORE_EDIT(state, addr = {}) {
      state.addrEdit = addr
    },
    OPEN_LOGIN_BOX(state, active) {
      state.loginBoxOpen = active
    },
    OPEN_COUPON_BOX(state,active){
      state.couponBoxOpen = active
    },
    SET_USER_IFNO(state, data = {}) {
      state.user = data
    },
    SET_LOGIN_LOADING(state, data = false) {
      state.loginLoading = data
    },
    SET_SHOP_INFO(state, data = {}) {
      state.shopInfo = data
    },
    SET_APP_LOADING(state, data) {
      state.appLoading = data
    },
    CLEAE_ALL(state) {
      state.user = {}
      state.cart = []
    },
    ON_CHANGE_CART(state, data) {
      state.cartChanged = data
    },
    CART_LOADING(state, data) {
      state.cartLoading = data
    },
    // 这个不需要了
    SET_CART_LIST(state, data) {
      state.cart.list[0].carts = data
    },
    SET_AREA(state, data) {
     
      state.areas = data
    },
    SET_CLASSIFY(state, data = []) {
      state.category = data
    }
  },
  actions: {
    getCategoryList({ state, commit }) {
      if (state.category.length > 0) {
        return
      }
      let p = { type: 'children' }
      categoryList(p).then(res => {
        if (res.data.status === 'SUCCESSS') {
          let category
          if (res.data.data.length === 1) {
            category = res.data.data[0].sons[0].sons
          } else {
            category = res.data.data
          }
          commit('SET_CLASSIFY', category)
        }
      })
      // .catch(err => {
      //   // console.log(err)
      // })
    },
    clearData({ commit }) {
      commit('CLEAE_ALL')
      window.localStorage.clear()
    },
    setLoginLoading({ commit }, data) {
      commit('SET_LOGIN_LOADING', data)
    },
    setUserInfo({ commit }, data) {
      commit('SET_USER_IFNO', data)
      window.localStorage.setItem('user_info', JSON.stringify(data))
    },
    openLoginBox({ commit }, active) {
      commit('OPEN_LOGIN_BOX', active)
    },
    opencouponBox({commit},active){
      commit('OPEN_COUPON_BOX',active)
    },
    addCart({ commit }, goods = '') {
      commit('ADD_CART', goods)
    },
    setCart({ commit }, goods = '') {
      commit('SET_CART', goods)
      commit('CART_LOADING', false)
    },
    storeAddr({ commit }, address = []) {
      commit('STORE_ADDR', address)
    },
    addrEdit({ commit }, data = {}) {
      commit('STORE_EDIT', data)
    },
    setShopInfo({ commit }, data = {}) {
      window.sessionStorage.setItem('shop_info', JSON.stringify(data))
      commit('SET_SHOP_INFO', data)
    },
    setAppLoading({ commit }, data = false) {
      commit('SET_APP_LOADING', data)
    },
    onChangeCart({ commit }) {
      commit('ON_CHANGE_CART', Date.now())
    },
    cartLoading({ commit }, data = false) {
      commit('CART_LOADING', data)
    },
    // delete 不需要了
    changShopCart({ commit }, data) {
      commit('SET_CART_LIST', data)
    },
    setAreas({ commit }, data) {
      window.localStorage.setItem('shop_areas', JSON.stringify(data))
      commit('SET_AREA', data)
    }
  }
})
